<template>
  <div
    class="bg p20 con"
    style="display: flex; position: relative"
  >
    <div
      class="df f-w box"
      style="overflow: hidden;min-height: 35.875rem;height: 48.875rem;"
    >
      <div class="hot_video">
        <div class="hot_video_text">热门视频</div>
        <!--            <div style="position: relative;display: flex;margin: -25px 1040px;">-->
        <div style="position: relative;display: flex;margin: -25px 800px;">
          <el-button
            style="border: none; padding-left: 14px;padding-top: 8px; margin-right: 10px"
            class="hot_btn"
            @click="navTo('shortVideo')"
          >去创作</el-button>
          <el-button
            type="primary"
            class="hot_pri"
            icon="el-icon-arrow-left"
            @click="video_up_page"
          ></el-button>
          <el-button
            type="primary"
            class="hot_pri"
            icon="el-icon-arrow-right"
            @click="video_next_page(videoList.length)"
          ></el-button>
        </div>
      </div>
      <div
        v-for="(item, idx) in videoList"
        v-if="videoList.length > 0"
        :key="idx"
        class="imgBox hot_img_box"
        @click="getItem_video(item)"
      >
        <img
          :src="item.video_image"
          object-fit="cover"
          alt=""
        />
        <div
          class="video_type"
          style="margin-left: 14px"
        >已完成</div>
        <div class="itools">{{ item.title }}</div>
        <div class="it_date">{{ item.create_time }}</div>
        <div class="it_line"></div>
        <div class="it_download">
          <div class="download_img">
            <!-- <img src="~@/assets/img/download001.png"> -->
          </div>
          <div class="download_count">{{ item.file_id }}次</div>
          <div class="download_btn">
            <el-button style="padding-top: 8px">下载</el-button>
          </div>
        </div>
      </div>
      <div
        v-if="videoList.length < 1"
        style="margin-left: 500px"
      >
        <div>
          <myempty text="视频"></myempty>
        </div>
      </div>
      <div
        class="hot_video"
        style="margin-top: -120px"
      >
        <div class="hot_video_text">热门直播</div>
        <!--            <div style="position: relative;display: flex;margin: -25px 1040px;">-->
        <div style="position: relative;display: flex;margin: -25px 800px;">
          <el-button
            style="border: none; padding-left: 14px;padding-top: 8px; margin-right: 10px"
            class="hot_btn"
            @click="navTo('live')"
          >去创作</el-button>
          <el-button
            type="primary"
            class="hot_pri"
            icon="el-icon-arrow-left"
            @click="live_up_page"
          ></el-button>
          <el-button
            type="primary"
            class="hot_pri"
            icon="el-icon-arrow-right"
            @click="live_next_page(liveList.length)"
          ></el-button>
        </div>
        <div v-if="liveList.length >0">
          <div class="hot_live_img">
            <div
              v-for="(item, idx) in liveList"
              :key="idx"
              class="live_div_img"
              @click="getItem_live(item)"
            >
              <img
                :src="item.video_image"
                object-fit="cover"
                alt=""
              />
              <div class="img_text_div">
                <div class="img_text_div">
                  <div
                    style="margin-left: 20px; position: relative; width: 90%"
                    class="img_text_div2"
                  > {{ item.title }}</div>
                  <div
                    class="img_date"
                    style="color: white; position: relative;top: 5px"
                  >{{ item.create_time }}</div>
                  <div class="it_live">
                    <div class="live_img">
                      <!-- <img src="~@/assets/img/download_img.png"> -->
                    </div>
                    <div
                      class="download_count"
                      style="margin-top: -25px; margin-left: -50px"
                    >{{ item.file_id }}次</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            <myempty text="直播"></myempty>
          </div>
        </div>
      </div>
    </div>

    <!--右边功能区-->
    <div class="banner dfc">
      <!--      克隆-->
      <div
        class="ban dfc b3"
        style="margin-top: 20px"
      >
        <div class="text">
          <div class="tit">数字人克隆</div>
          <div class="desc">拍几分钟视频，即可克隆数字人</div>
          <!-- <div class="btn" @click="clone">克隆形象</div>
          <div class="btn" @click="clone">克隆声音</div> -->
          <el-button-group class="mt20 clone_btn">
            <el-button
              v-if="userInfo.calculations == 1 || userInfo.calculations == 3"
              type="primary"
              class="cloneBtn"
              @click="clone(1)"
            >克隆形象</el-button>
            <el-button
              v-if="userInfo.calculations == 2"
              type="success"
              class="cloneBtn"
              @click="clone(2)"
            >克隆高阶形象</el-button>
            <el-button
              type="warning"
              style="border-radius: 0px 0px 16px 0px;"
              class="cloneBtn"
              @click="clone(3)"
            >克隆声音</el-button>
          </el-button-group>
        </div>

        <img
          class="img_banner_right"
          style="width: 123px; height: 150px;margin-left: -20rem;margin-top: -22rem;"
          src="~@/assets/img/banner_people.png"
          alt=""
        />
      </div>
      <!--      创作-->
      <div
        class="ban dfc b1"
        style="margin-top: 40px;"
      >
        <div class="text">
          <div class="tit">AI创作</div>
          <div class="desc">向AI提问，一键生成视频方案</div>
          <el-button
            class="btn"
            @click="navTo('text')"
          >立即体验</el-button>
        </div>
        <img
          class="img_banner_right"
          style="width: 123px; height: 150px;margin-left: -20rem;margin-top: -20rem;"
          src="~@/assets/img/banner_creates.png"
          alt=""
        />
      </div>
      <!--      绘画-->
      <div
        class="ban dfc b2"
        style="margin-top: 40px;"
      >
        <div class="text">
          <div class="tit">AI绘画</div>
          <div class="desc">Ai智能绘画，所想即所见</div>
          <el-button
            class="btn"
            @click="navTo('painting')"
          >立即体验</el-button>
        </div>
        <img
          class="img_banner_right"
          style="width: 123px; height: 150px;margin-left: -20rem;margin-top: -20rem;"
          src="~@/assets/img/banner_draw.png"
          alt=""
        />
      </div>
    </div>

    <!-- <div class="bg-white df jc-sbn head br8">
      <div v-for="(i, idx) in catList" :key="i.id" class="cat" :class="idx == cur ? 'act' : ''" @click="change(i.id, idx)">{{ i.cat_name }}</div>
    </div> -->

  </div>
</template>
<script>
import { get_list, get_cat } from "@/api/user.js";
import myempty from "@/components/myempty.vue";
import { getVideo } from "@/api/video.js";
import liveList from "@/views/live/components/live/liveList";
import {ipcApiRoute} from "@/api/main";
import {ipc} from "@/utils/ipcRenderer";
export default {
  components: {
    myempty,
  },
  // name: "home",
  data () {
    return {
      cur: 0,
      id: "",
      videoList: [],
      videoItem: {},
      liveList: [],
      liveItem: {},
      catList: [],
      userInfo: {},
      videoPage: 1,
      videoPerPage: 5,
      livePage: 1,
      livePerPage: 4,
      testVideoList: [],
      testLiveList: [],
    };
  },
  computed: {
    videoListShow () {
      const start = (this.videoPage - 1) * this.videoPerPage
      const end = start + this.videoPerPage
      return this.videoList.slice(start, end)
    },
    liveListShow () {
      const start = (this.livePage - 1) * this.livePerPage
      const end = start + this.livePerPage
      return this.liveList.slice(start, end)
    }
  },
  mounted () {
    this.userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};

    this.get_cat();
    this.get_video_list();
    this.get_live_list();
  },
  methods: {
    init() {
      this.$ipc.invoke(ipcApiRoute.openSoftware, "app.exe").then((r) => {
        console.log("resourcePath:", r);
      });
    },
    openSoft(id) {
      ipc.invoke(ipcApiRoute.openSoftware, id).then(result => {
        if (!result) {
          this.$message.error('程序不存在');
        }
      })
    },
    clone (type) {
      // this.$notify({
      //   title: "克隆形象",
      //   message: "请联系客服添加克隆形象",
      // });
      if (type == 3) {
        this.$router.push({ name: "audioClone", current: 0 });
      } else {
        this.$router.push({ name: "clone", current: 0 });
      }
    },
    navTo (i) {
      this.$router.push({ name: i });
    },
    video_next_page (video_count) {
      if (video_count > 0) {
        this.videoPage += 1
        this.get_video_list()
      }
    },
    video_up_page () {
      if (this.videoPage > 1) {
        this.videoPage -= 1
        this.get_video_list()
      }
    },
    live_next_page (live_count) {
      if (live_count > 0) {
        this.livePage += 1
        this.get_live_list()
      }
    },
    live_up_page () {
      if (this.livePage > 1) {
        this.livePage -= 1
        this.get_live_list()
      }
    },
    getItem_live (item) {
      this.$router.push({ name: "live", params: { imageId: item.image_id } });
    },
    getItem_video (item) {
      this.$router.push({ name: "shortVideo", params: { imageId: item.image_id } });
    },
    change (i, idx) {
      this.cur = idx;
      this.id = i;
      this.getlist();
    },
    get_video_list () {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getVideo({
        page: this.videoPage,
        item: this.videoPerPage,
        type_s: 1,
        statusa: "",
        so: "",
      }).then((res) => {
        setTimeout(() => {
          loading.close();
          if (res.code == "200") {
            this.videoList = res.data.list;
            this.videoItem = res.data.list[0]
          } else {
            this.$message.error(res.msg);
          }
        }, 200);
      });
    },
    get_live_list () {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getVideo({
        page: this.livePage,
        item: this.livePerPage,
        type_s: 2,
        statusa: "",
        so: "",
      }).then((res) => {
        setTimeout(() => {
          loading.close();
          if (res.code == "200") {
            this.liveList = res.data.list;
            this.liveItem = res.data.list[0]
            console.log(res.data.list[0])
          } else {
            this.$message.error(res.msg);
          }
        }, 200);
      });
    },
    getlist () {
      // get_list({ cat_id: this.id }).then((res) => {
      //   if (res.code == "200") {
      //     this.videoList = res.data.list;
      //   } else {
      //     this.$message.error(res.msg);
      //   }
      // });
    },
    get_cat () {
      get_cat().then((res) => {
        if (res.code == "200") {
          this.catList = res.data;
          this.id = this.catList[0].id;
          this.getlist();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.hot_video {
  position: relative;
  width: 72rem;
  height: 30px;
  opacity: 1;
  /*background-color: red;*/
  margin-top: 22px;
  .hot_video_text {
    position: relative;
    left: 0px;
    top: 6px;
    width: 88px;
    height: 18px;
    opacity: 1;
    /** 文本1 */
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 18px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
  }
  .hot_btn {
    position: relative;
    display: flex;
    left: 10px;
    top: 10px;
    width: 86px;
    height: 30px;
    opacity: 1;
    border-radius: 5px;
    text-align: center;
    background: linear-gradient(
      90deg,
      rgba(54, 243, 170, 1) 0%,
      rgba(4, 221, 181, 1) 100%
    );
    color: #000c17;
  }
  .hot_pri {
    width: 30px;
    position: relative;
    height: 30px;
    margin-top: 10px;
    padding-left: 5px;
    padding-top: 7px;
    display: flex;
    float: left;
    margin-left: 5px;
    opacity: 1;
    border-radius: 4px;
    ::v-deep {
      .el-button--primary {
        background: #3f3f3f00 !important;
      }
    }
  }
  .hot_img_box {
    margin-top: 20px;
    width: 1120px;
    height: 565px;
  }
}
.video_type {
  z-index: 10;
  margin-bottom: 288px;
  margin-top: -20.8rem;
  position: relative;
  padding: 3px 6px;
  display: flex;
  width: 62px;
  height: 20px;
  opacity: 1;
  border-radius: 3px;
  background: rgba(25, 74, 249, 1);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 12px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  vertical-align: top;
}
::v-deep {
  .el-button {
  }
}
.hot_live_img {
  position: relative;
  top: 40px;
  width: 72rem;
  height: 130px;
  /*background: red;*/
  z-index: 1;
  .live_div_img {
    position: relative;
    z-index: 2;
    display: flex;
    float: left;
    top: 4px;
    margin-right: 20px;
    width: 16.5rem;
    height: 125px;
    /*background: #5daf34;*/
    img {
      z-index: 3;
      width: 70px;
      height: 124px;
      background-color: #243748;
      border-radius: 10px;
    }
  }
}
.img_text_div {
  position: relative;
  z-index: 10;
  left: 0px;
  top: 25px;
  width: 12rem;
  height: 100px;
  opacity: 1;
  border-radius: 6px;
  background: #080114;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.it_live {
  position: relative;
  height: 32px;
  width: 208px;
  margin-top: 10px;
  .live_img {
    position: relative;
    width: 19px;
    height: 22px;
    left: 18px;
    opacity: 1;
    background: url(~@/assets/img/download001.png) no-repeat center 0px;
    background-position: center 0px;
    background-size: cover;
    img {
      position: relative;
      width: 13px;
      height: 13px;
      top: -2px;
    }
  }
}
.img_text_div2 {
  text-align: left;
  position: relative;
  opacity: 1;
  z-index: 50;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.img_date {
  position: relative;
  left: 20px;
  top: 4.375rem;
  width: 119px;
  height: 18px;
  opacity: 0.5;
  /** 文本1 */
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 18px;
  color: rgba(255, 255, 255, 1);
  text-align: left;
  vertical-align: top;
}
.con {
  height: 917px;
  overflow: hidden;
}
.banner {
  position: relative;
  width: 40rem;
  height: 51rem;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  right: 15px;
  .ban {
    position: relative;
    width: 31.5rem;
    height: 13rem;
    padding: 40px 10px 30px 40px;
    opacity: 1;
    border-radius: 11.22px;
    background: linear-gradient(
      90deg,
      rgba(36, 57, 73, 1) 0%,
      rgba(28, 24, 64, 1) 100%
    );
    background-size: cover;

    img {
      width: 200px;
      height: 200px;
    }

    .img_banner_right {
      position: relative;
      margin-left: -22rem;
      margin-top: -10rem;
      width: 123px;
      height: 150px;
      opacity: 1;
    }
    .text {
      width: 270px;
      text-align: left;
      margin-left: 9rem;
      margin-top: 2rem;

      .tit {
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 46.34px;
        background: linear-gradient(
          134.44deg,
          rgba(117, 232, 255, 1) 0%,
          rgba(145, 48, 255, 1) 100%
        );
        -webkit-background-clip: text;
        /*background-clip: text;*/
        color: transparent;
        text-align: left;
        vertical-align: top;
        font-family: "微软雅黑";
        margin-left: 35px;
      }
      .desc {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(145, 165, 255, 1);
        text-align: left;
        vertical-align: top;
        margin-top: 5px;
        font-family: "微软雅黑";
        margin-left: 2.25rem;
      }

      .btn {
        color: rgba(2, 38, 30, 1);
        width: 146px;
        height: 40px;
        opacity: 1;
        line-height: 10px;
        text-align: center;
        border-radius: 16px 0px 16px 0px;
        background: linear-gradient(
          90deg,
          rgba(54, 243, 170, 1) 0%,
          rgba(4, 221, 181, 1) 100%
        );
        margin: 2.125rem 10.8rem;
        font-weight: 500;
        font-size: 18px;
      }
      .clone_btn {
        position: relative;
        width: 18.75rem;
        margin: 2.3rem 30px;
        height: 3.125rem;
        .cloneBtn {
          width: 146px;
          height: 45px;
          border-radius: 16px 0px 0px 0px;
        }
      }
    }
  }
}
::v-deep {
  .el-button--warning {
    color: rgba(32, 37, 50, 1);
    border-color: rgba(4, 221, 181, 1);
    background: linear-gradient(
      90deg,
      rgba(54, 243, 170, 1) 0%,
      rgba(4, 221, 181, 1) 100%
    );
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 15px;
  }
}
.cat {
  margin-right: 10px;
}

.act {
  color: #015ce6;
}

.box {
  min-height: 574px;
  height: 574px;
  width: 101%;
}

.imgBox {
  margin-right: 20px !important;
  /*height: 336px;*/
  margin-top: -6rem;
  width: 208px;
  height: 29.3125rem;
  /*background: red;*/

  img {
    margin-top: 10px;
    border-radius: 0px;
    background-color: #243748;
    border-radius: 10px;
  }

  .itools {
    position: absolute;
    padding-top: 10px;
    margin: 35px 10px;
    width: 190px;
    height: 30px;
    opacity: 1;
    /** 文本1 */
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 12px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  .it_date {
    position: relative;
    left: 10px;
    top: 4.375rem;
    width: 119px;
    height: 18px;
    opacity: 0.5;
    /** 文本1 */
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 18px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
  }
  .it_line {
    position: relative;
    left: 0px;
    top: 4.6rem;
    width: 208px;
    height: 1px;
    opacity: 0.1;
    background: rgba(216, 216, 216, 1);
  }
  .it_download {
    position: relative;
    height: 32px;
    width: 208px;
    margin-top: 90px;
    .download_img {
      position: relative;
      width: 19px;
      height: 22px;
      top: 5px;
      left: 10px;
      opacity: 1;
      // border-radius: 4px;
      // background: rgba(255, 71, 56, 1);
      // box-shadow: 0px 0px 12px rgba(255, 71, 56, 0.5);
      background: url(~@/assets/img/download001.png) no-repeat center 0px;
      background-position: center 0px;
      background-size: cover;
      img {
        position: relative;
        width: 13px;
        height: 13px;
        top: -6px;
      }
    }
  }
  .download_count {
    position: relative;
    left: 2rem;
    top: -0.65rem;
    width: 80px;
    height: 10px;
    opacity: 1;
    /** 文本1 */
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 10px;
    color: rgba(255, 255, 255, 1);
    text-align: left;
    vertical-align: top;
  }
  .download_btn {
    position: relative;
    left: 8.5rem;
    top: -1.95rem;
    width: 70px;
    height: 30px;
    opacity: 1;
    border-radius: 5px;
    ::v-deep {
      .el-button {
        position: relative;
        color: white;
        width: 70px;
        border: none;
        height: 30px;
        background: linear-gradient(
          90deg,
          rgba(76, 76, 252, 1) 0%,
          rgba(63, 0, 222, 1) 100%
        );
      }
    }
  }
}
</style>
